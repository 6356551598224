.main_navbar,.features_opt_dtls{
  background-color: #ffcc00 !important;
}
.header_main_logo{
  height: 50px;
  width: 50px;
}
.navbar-toggler,.navbar-toggler:focus,.navbar-toggler:active{
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.header_download_btn{
  background-color: #000 !important;
  color: #fff !important;
  border-radius: 30px !important;
  padding: 8px 20px !important;
  font-size: 13px !important;
  border: none;
  outline: none;
  box-shadow: none;
}

.banner_top_align{
  padding-top: 70px;
}
.banner_title,.doodle_title{
  font-weight: 700 !important;
}
.explore_content_hintone{
  font-size: 15px;
  font-weight: 700;
}
.banner_hint,.explore_content_hint{
  font-weight: 500 !important;
}
.banner_bg{
  background-color: #f9f9f9 !important;
}
.features_icons{
  font-size: 25px !important;
}
.features_hint_txt{
  font-size: 13px !important;
  line-height: 1.5 !important;
  font-weight: 500;
  color: #fff;
}
.explore_content_icons{
  font-size: 50px !important;
  color: #ffcc00 !important;
}
.explore_content_download_btn{
  background-color: #000 !important;
  color: #fff !important;
  border: none !important;
  outline: none !important;
  padding: 5px 25px !important;
  border-radius: 30px !important;
  font-size: 15px !important;
}
.explore_content_download_btn:hover{
  background-color: transparent !important;
  outline: 2px solid #000 !important;
  color: #000 !important;
  font-weight: 500;

}
.simple_steps_img{
  
  width: 13rem !important;
  height: 230px !important;
}
.footer{
  background-color: #f9f9f9 !important;
}
.footer_logo_img{
  height: 100px !important;
  width: 100px !important;
}
.social_icons_dtls{
  display: flex;
  justify-content: start;
  align-items: center;
}
.social_icons_dtls img{
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.twitter_align{
  height: 25px;
  width: 30px;
  border-radius: 50%;
  background-color: #000 !important;
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.twitter_social,.discard_social{
  font-size: 15px !important;
}
.social_links{
  font-size: 25px !important;
 
}
.footer_listing p{
  font-size: 18px;
  font-weight: 700 !important;

}
.footer_listing ul{
  list-style-type: none !important;
  padding-left: 0 !important;
}
.footer_listing ul li{
  font-size: 14px !important;
  margin-bottom: 5px !important;
  cursor: pointer;
}
.footer_listing ul li:hover{
  color: #daae00 !important;
}
.simple_steps_dtls{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.explore_content_download_align{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
}
.banner_btn_align{
  display: flex;
  flex-wrap: wrap !important;
}
.download_icon_dtls{
  background-color: #000 !important;
  border-radius: 30px !important;
  width: 180px;
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}
.download_icon_dtls:hover{
  background-color: transparent !important;
  outline: 2px solid #000;
  
}
.download_icon_dtls:hover .download_txt_align,.download_icon_dtls:hover .download_hint_align{
  color: #000 !important;
}
.download_icon_dtls:hover .download_icons_align{
filter: invert(1) !important;
}
.download_icon_dtls .download_icons_align{
  height: 25px;
  width: 20px;
}
.download_icon_dtls .download_txt_align{
  color: #fff !important;
  font-size: 12px !important;
  line-height: 0 !important;
}
.download_icon_dtls .download_hint_align{
  font-size: 15px !important;
  color: #fff !important;
  line-height: 0 !important;
}
.yellow_header_img_align{
  height: 35px !important;
  width: 40px !important;
  min-height: 35px !important;
  min-width: 40px !important;
  max-height: 35px !important;
  max-width: 40px !important;
  filter: invert(1) !important;
}
.shield_img_align{
  height: 35px !important;
  width: 30px !important;
  min-height: 35px !important;
  min-width: 30px !important;
  max-height: 35px !important;
  max-width: 30px !important;
  filter: invert(1) !important;

}
.web3_images_align{
  height: 60px !important;
  width: 60px !important;
  min-height: 60px !important;
  min-width: 60px !important;
  max-height: 60px !important;
  max-width: 60px !important;
}
.download_now_btn_align{
  padding: 8px 25px;
  border: none;
  outline: none;
  background-color: #000;
  color: #fff;
  border-radius: 30px;
  font-size: 15px;
  cursor: pointer;
}
.download_now_btn_align:hover{
  outline: 1px solid #000;
  color: #000;
  background-color: transparent;
  font-weight: 600;
}
.companion_div_align{
  height: 80px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center !important;
  border: 1px solid lightgray;
  font-weight: 400;
}
.tick_img_align{
  height: 25px;
  width: 25px;
  margin-right: 10px;
}
.world_asset_div_align{
  padding: 10px;
  border: 1px solid #f2f2f2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.world_asset_div_alignOne{
  padding: 10px;
 
  display: flex;
  justify-content: flex-start;
  align-items: center;

}
.tokens_img_align{
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.stacking_clearance_txt{
  
  font-size: 13px;
  font-weight: 500;
}
.stacking_brief_clearance_txt{
  font-size: 13px;
  font-weight: 500;

}
.stacking_brief_align{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}
.whole_steps_align{
  display: flex;
  justify-content: center;
  align-items: center;
}
.box_text_align{
  padding: 40px;
  margin-top: 20px;
  border: 1px solid lightgray;
}
.switch_acnt_align{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  border: 1px solid lightgray;
}
.danger_text{
  color: red;
  font-weight: 500;
}
.switch_acnt_icon_align{
  font-size: 20px;
}
.switch_acnt_txt_align{
  display: flex;
  justify-content: center;
  align-items: flex-end;

}
.blue_text{
  color: blue;
  font-weight: 600;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer;
}
.name_input_align{
  border: none;
  outline: none;
  border-bottom: 1px solid grey !important;
  width: 60%;
}
.input_group_text_align{
  background-color: transparent !important;
  padding: 0px 5px 0px 0px !important;
  border: none !important;
  outline: none !important;
}
.form_check_input_align:focus{
  box-shadow: none !important;

}
.input_group_label_align{
  border: none !important;
}
.clear_form_div_align{
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.small_skyblue_text{
  font-size: 13px;
  color: skyblue;
  font-weight: 600;
  cursor: pointer;
}
.small_skyblue_text:hover{
  color: #000 !important;
}
.collection_dtls_align{
  display: flex;
  margin-top: 20px;
  justify-content: flex-start;
  align-items: center;
}
.yellow_round_align{
  height: 10px;
  width: 10px ;
  background-color: #ffcc00;
  border-radius: 50%;
  margin-right: 10px;
}
.footer_mail_input_align{
  border: none;
  outline: none;
  background-color: transparent;
  padding: 8px 10px;
}
.email_input_dtls{
  display: flex;
  justify-content: space-between;
  border: 1px solid #000;
  border-right: none !important;
  border-radius: 30px;
}

.link_tag_align{
  text-decoration: none;
  color: #000;
  cursor: pointer;
}
.link_tag_align:hover{
  color: #ffcc00 ;
}
.link_tag_alignhead{
  text-decoration: none !important;
  color: #000 !important;
}

.link_tag_alignhead:hover{

  color: #fff !important;
  
  
}
.header_download_btn:hover{
  background-color: transparent !important;
  outline: 2px solid #000;
  color: #000 !important;
}
.support_email_txt_align{
  text-decoration: none ;
  font-weight: 600;
}
.support_email_txt_align:hover{
  color: #000;
}
.dowload_icon_dtls_align{
  justify-content: space-between;
  align-items: center;
}
.header_offcanva_align{
  background-color: #ffcc00 !important;
}
.whole_firstdowload_icon_dtls_align{
  display: flex;
  justify-content: flex-end;
}
.whole_seconddowload_icon_dtls_align{
  display: flex;
  justify-content: center;

}
.whole_thirddowload_icon_dtls_align{
  display: flex;
  justify-content: flex-start;

}
.one_million_whole_align{
  text-align: center;
  margin-top: 15px;
  
}
.one_million_text_align{
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  text-decoration: none !important;
  position: relative;
  
}
.one_million_text_align::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: gray;
  left: 0;
  bottom: 0;
}
.header_bar_sticky_align{
  position: sticky !important;
  top: 0 !important;
}




























@media (319px < width < 576px){
  .footer_listing{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .footer_listing p{
    text-align: center !important;
  }
  .footer_listing ul li{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .dapp_img_ctntdiv_align{
    text-align: center;
  }
  .dapp_img_ctnt_align{
    max-width: 300px !important;
  }
  .switch_acnt_txt_align,.switch_acnt_icon_align{
    font-size: 10px !important;
  }
  .never_submit_text{
    font-size: 12px;

  }
  .name_input_align{
    width: 100% !important;
  }




}
@media (319px < width < 768px){
  .whole_seconddowload_icon_dtls_align,.whole_firstdowload_icon_dtls_align,.whole_thirddowload_icon_dtls_align{
    display: flex;
    justify-content: center;
  
  }
}



@media (max-width: 767px){
  .row_reverse_align{

    flex-direction: column-reverse !important;
  }
}
@media (min-width: 992px){
  .navbar-expand-lg .navbar-nav .nav-link{
    margin-right: 60px !important;
    font-weight: 500;
  }
}
